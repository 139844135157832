import "katex/dist/katex.min.css";

import React from "react";
import Link from "gatsby-link";
import Layout from "../components/layout";
import Subscribe from "../components/subscribe";
import SEO from "../components/SEO";

export default function Template({ data, pageContext }) {
  const { next, prev } = pageContext;
  const post = data.markdownRemark;
  /*console.log("image data: ");
  console.log(post.frontmatter.featuredImage.childImageSharp.fluid.src);*/

  return (
    <div>
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          image={post.frontmatter.featuredImage.childImageSharp.fluid.src}
        />

        {prev && (
          <Link to={prev.node.frontmatter.path}>
            {" "}
            <i class="material-icons small">keyboard_arrow_left</i>
          </Link>
        )}
        {next && (
          <Link to={next.node.frontmatter.path}>
            {" "}
            <i class="material-icons small right">keyboard_arrow_right</i>
          </Link>
        )}

        <hr />
        <h2>{post.frontmatter.title}</h2>
        <small>Posted on {post.frontmatter.date}</small>
        <br />
        <br />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <br />
        <br />

        <Subscribe />
      </Layout>
    </div>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      frontmatter {
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        author
        date
        tags
        index
      }
    }
  }
`;
